.header_container {
  display: flex;
  align-items: center;
  margin: 0 -20px 20px;
  color: #000000;
  border-radius: 0 !important;
}
.header__icon, .header__text {
  line-height: 1;
}
.header__icon {
  cursor: pointer;
}
.header__text {
  font-family: "Brown-Bold";
  font-size: 20px;
  margin-left: 10px;
}

.price_card {
  padding: 15px;
  font-family: "Brown-Regular";
  margin-bottom: 20px;
}
.price_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.price__img {
  object-fit: contain;
  padding-right: 15px;
}
.price__amount {
  flex: 1;
}
.price__amount_text {
  opacity: 0.54;
  font-size: 14px;
  line-height: 1;
  margin-bottom: 5px;
}
.price__amount_field {
  display: flex;
}
.price__amount__currency, .price__amount__textbox {
  font-size: 25px;
  line-height: 1;
}
.price__amount__textbox {
  width: 100%;
  margin-left: 5px;
  border-style: none;
  background-color: transparent;
}
.price__amount__textbox_edit {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  background-color: transparent;
}
.price__amount__textbox:focus {
  outline: none;
}
.price__edit {
  color: #ee2a24;
  font-size: 16px;
  padding-left: 15px;
  cursor: pointer;
}

.form_card {
  padding: 15px;
  font-family: "Brown-Regular";
}
.form_header h3 {
  font-family: "Brown-Bold";
  font-size: 20px;
  line-height: 1;
  margin-bottom: 10px;
}
.form_header h4 {
  font-size: 14px;
  opacity: 0.8;
  line-height: 1.5;
  margin-bottom: 10px;
}
.form_schema_card {
  background-color: transparent;
  border-style: none;
}
.form_schema_card .ant-card-body {
  padding: 0;
}
.form_schema .MuiGrid-item {
  border-radius: 4px;
  margin-bottom: 20px !important;
  padding: 10px !important;
  background-color: #ffffff;
  box-shadow: 2px 4px 8px 0 rgba(46, 61, 73, 0.2);
}
.form_schema .MuiInputLabel-formControl {
  top: -7px;
  font-size: 16px;
  font-family: "Brown-Regular";
}
.form_schema .MuiFormLabel-root.Mui-focused {
  color: #ee2a24;
}
.form_schema .MuiFormLabel-root.Mui-focused.MuiInputLabel-formControl, .form_schema .MuiFormLabel-root.MuiFormLabel-filled.MuiInputLabel-formControl {
  top: 0;
}
.form_schema .MuiInput-underline {
  font-family: "Brown-Regular";
}
.form_schema .MuiInput-underline:before, .form_schema .MuiInput-underline:after, .form_schema .MuiInput-underline:hover:not(.Mui-disabled):before, .form_schema .MuiInput-underline.Mui-disabled:before {
  border-style: none;
}
.form_schema .MuiInput-underline .MuiInputBase-input {
  padding-bottom: 0;
}
.form__button {
  width: 100%;
  margin: 20px 0;
  font-size: 16px;
  border-radius: 100px;
  background-color: #ee2a24;
  border-color: #ee2a24;
  text-transform: capitalize;
}
.form__button:hover, .form__button:active, .form__button:focus {
  background-color: #ee2a24;
  border-color: #ee2a24;
}

.container {
  background-color: #ffffff;
  margin: 0;
}

.card {
  background-color: #f6f5f5;
  padding: 0 20px;
}
@media (max-width: 768px) {
  .card {
    padding: 0 10px;
  }
}

.card_container {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 2px 4px 8px 0 rgba(46, 61, 73, 0.2);
}