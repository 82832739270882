.price {
  &_card {
    padding: 15px;
    font-family: 'Brown-Regular';
    margin-bottom: 20px;
  }
  &_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__img {
    object-fit: contain;
    padding-right: 15px;
  }
  &__amount {
    flex: 1;
    &_text {
      opacity: 0.54;
      font-size: 14px;
      line-height: 1;
      margin-bottom: 5px;
    }
    &_field {
      display: flex;
    }
    &__currency,
    &__textbox {
      font-size: 25px;
      line-height: 1;
    }
    &__textbox {
      width: 100%;
      margin-left: 5px;
      border-style: none;
      background-color: transparent;
      &_edit {
        border-bottom: 1px solid rgba(0, 0, 0, 0.5);
        background-color: transparent;
      }
      &:focus {
        outline: none;
      }
    }
  }
  &__edit {
    color: $red;
    font-size: 16px;
    padding-left: 15px;
    cursor: pointer;
  }
}
