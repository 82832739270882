@import "~antd/dist/antd.css";

$gray-7: #8c8c8c;
$red-5: #ff4d4f;
$red-6: #f5222d;
$red-1: #fff1f0;
$red-2: #ffccc7;
$green-7: #389e0d;

.antd_modified {
  .ant-input:hover,
  .ant-select-selection:hover,
  .ant-calendar-picker:hover
    .ant-calendar-picker-input:not(.ant-input-disabled),
  .ant-calendar .ant-calendar-ok-btn:focus,
  .ant-calendar .ant-calendar-ok-btn:hover,
  .ant-calendar-range .ant-calendar-input:hover,
  .ant-calendar-range .ant-calendar-time-picker-input:hover {
    border-color: $gray-7 !important;
  }

  .ant-input:focus,
  .ant-select-open .ant-select-selection,
  .ant-select-focused .ant-select-selection,
  .ant-select-selection:focus,
  .ant-select-selection:active,
  .ant-calendar-picker:focus
    .ant-calendar-picker-input:not(.ant-input-disabled),
  .ant-calendar-range .ant-calendar-input:focus,
  .ant-calendar-range .ant-calendar-time-picker-input:focus {
    border-color: $gray-7 !important;
    -webkit-box-shadow: 0 0 0 2px rgba(89, 89, 89, 0.2);
    box-shadow: 0 0 0 2px rgba(89, 89, 89, 0.2);
  }

  .ant-calendar .ant-calendar-ok-btn:focus,
  .ant-calendar .ant-calendar-ok-btn:hover {
    border-color: $red-5 !important;
    background-color: $red-5 !important;
  }

  .ant-calendar .ant-calendar-ok-btn,
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $red-6;
    border-color: $red-6;
  }

  .ant-calendar-date:active {
    background: $red-5 !important;
  }

  .ant-calendar-date:hover,
  .ant-calendar-range .ant-calendar-in-range-cell::before,
  .ant-calendar-month-panel-month:hover,
  .ant-calendar-year-panel-year:hover,
  .ant-calendar-decade-panel-decade:hover,
  .ant-calendar-week-number .ant-calendar-body tr:hover {
    background: $red-1 !important;
  }

  .ant-calendar-today .ant-calendar-date,
  .ant-calendar-range
    .ant-calendar-today
    :not(.ant-calendar-disabled-cell)
    :not(.ant-calendar-last-month-cell)
    :not(.ant-calendar-next-month-btn-day)
    .ant-calendar-date {
    color: $red-6 !important;
    border-color: $red-6 !important;
  }

  .ant-calendar-selected-day .ant-calendar-date,
  .ant-calendar-range
    .ant-calendar-today
    :not(.ant-calendar-disabled-cell)
    :not(.ant-calendar-last-month-cell)
    :not(.ant-calendar-next-month-btn-day)
    .ant-calendar-date {
    background: $red-2 !important;
  }

  .ant-input-group-addon .ant-select-open .ant-select-selection,
  .ant-input-group-addon .ant-select-focused .ant-select-selection,
  .ant-calendar-today-btn {
    color: $red-6 !important;
  }

  .ant-calendar-month-panel-header a:hover,
  .ant-calendar-year-panel-header a:hover,
  .ant-calendar-decade-panel-header a:hover,
  .ant-calendar-header a:hover {
    color: $red-5 !important;
  }

  .ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date,
  .ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date,
  .ant-calendar-range
    .ant-calendar-selected-start-date
    .ant-calendar-date:hover,
  .ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date:hover,
  .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month,
  .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month:hover,
  .ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year,
  .ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year:hover,
  .ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade,
  .ant-calendar-decade-panel-selected-cell
    .ant-calendar-decade-panel-decade:hover {
    background: $red-6 !important;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-checked::after {
    border-color: $red-6 !important;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: $red-6 !important;
  }

  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: $red-1 !important;
  }

  .ant-upload-list-picture .ant-upload-list-item-done,
  .ant-upload-list-picture-card .ant-upload-list-item-done {
    border-color: $green-7;
  }

  .ant-upload-list-item-done,
  .ant-upload-list-item-done .anticon-paper-clip,
  .ant-upload-list-item-done .ant-upload-list-item-name {
    color: $green-7;
  }

  .ant-upload {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
