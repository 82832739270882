body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Brown-Bold";

  src: url("./fonts/brownbold.ttf") format("truetype"), /* Safari, Android, iOS */
    url("./fonts/brownbold.woff") format("woff"), /* Modern Browsers */
    url("./fonts/brownbold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Brown-Light";

  src: url("./fonts/brownlight.ttf") format("truetype"), /* Safari, Android, iOS */
    url("./fonts/brownlight.woff") format("woff"), /* Modern Browsers */
    url("./fonts/brownlight.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Brown-Regular";

  src: url("./fonts/brownregular.ttf") format("truetype"), /* Safari, Android, iOS */
    url("./fonts/brownregular.woff") format("woff"), /* Modern Browsers */
    url("./fonts/brownregular.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}
