@import '../../../scss/color.scss';
@import './header.scss';
@import './price.scss';
@import './form.scss';

.container {
  background-color: $white;
  margin: 0;
}

.card {
  background-color: $grey;
  padding: 0 20px;
  @media (max-width: 768px) {
    padding: 0 10px;
  }
}

.card_container {
  padding: 20px;
  background-color: $white;
  border-radius: 4px;
  box-shadow: 2px 4px 8px 0 rgba(46,61,73,.2);
}
