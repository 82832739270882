.App {
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    /* background-color: #000; */
  }
  th,td{
    padding: 5px;
  }
  .Wrapper{
    display:flex;
    flex:1;
    border:  solid 1px #f2f2f2;
    padding:10px;
    margin: auto;
  }