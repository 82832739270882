.form {
  &_card {
    padding: 15px;
    font-family: 'Brown-Regular';
  }
  &_header {
    h3 {
      font-family: 'Brown-Bold';
      font-size: 20px;
      line-height: 1;
      margin-bottom: 10px;
    }
    h4 {
      font-size: 14px;
      opacity: 0.8;
      line-height: 1.5;
      margin-bottom: 10px;
    }
  }
  
  &_schema {
    &_card {
      background-color: transparent;
      border-style: none;
      .ant-card-body {
        padding: 0;
      }
    }
    .MuiGrid-item {
      border-radius: 4px;
      margin-bottom: 20px !important;
      padding: 10px !important;
      background-color: $white;
      box-shadow: 2px 4px 8px 0 rgba(46,61,73,.2);
    }
    .MuiInputLabel-formControl {
      top: -7px;
      font-size: 16px;
      font-family: 'Brown-Regular';
    }
    .MuiFormLabel-root {
      &.Mui-focused {
        color: $red;
      }
      &.Mui-focused,
      &.MuiFormLabel-filled {
        &.MuiInputLabel-formControl {
          top: 0;
        }
      }
    }
    .MuiInput-underline {
      font-family: 'Brown-Regular';
      &:before,
      &:after,
      &:hover:not(.Mui-disabled):before,
      &.Mui-disabled:before {
        border-style: none;
      }
      .MuiInputBase-input {
        padding-bottom: 0;
      }
    }
  }
  &__button {
    width: 100%;
    margin: 20px 0;
    font-size: 16px;
    border-radius: 100px;
    background-color: $red;
    border-color: $red;
    text-transform: capitalize;
    &:hover,
    &:active,
    &:focus {
      background-color: $red;
      border-color: $red;
    }
  }
}  
