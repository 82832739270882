.mainContainer {
  position: relative;

  &_center_children {
    display: flex;
    width: 100vw;
    height: 100vh;
    // align-items: center;
    justify-content: center;
  }

  &_loader {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.receipt {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  width: 40%;
  border: 40px solid #f6f5f5;

  &__no_border_center_child {
    border: 0px;
    position: initial;
    transform: initial;
    padding-top: 30px;
  }

  &__hotel_detail {
    text-align: center;
    background-color: white;
    padding: 10px 10px 0px 10px;
    font-size: 16px;
    &--hotelName {
      color: black;
    }
  }

  &__header {
    padding: 20px 0px;
    background: #ee2e24;
    text-align: center;

    &--logo {
      width: 20%;
    }
  }

  &__heading {
    padding: 20px 0px;
    font-size: 14px;
    text-align: center;
    color: #ee2e24;
    text-transform: uppercase;
    position: relative;

    &::after {
      content: ' ';
      display: inline-block;
      width: 40px;
      height: 1px;
      font-size: 2px;
      background: #ee2e24;
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__heading_hms {
    color: black;
  }

  &__paymentAmount {
    text-align: center;
    padding: 30px 0px;

    &--amt {
      font-size: 1.2em;
      font-weight: bold;
    }
  }

  &__details {
    padding: 20px 20px 70px 20px;
  }

  &__detail {
    padding: 10px 0px;

    & > .left {
      float: left;
      color: #9c9999;
    }

    & > .right {
      float: right;
    }
  }

  &__divider {
    border-bottom: 1px dashed #eeeeee;
    clear: both;
    padding: 5px 0px;
  }

  &__footer {
    background-color: #f6f5f5;
    color: #9c9999;
    padding: 60px 60px 10px 60px;
    font-size: 0.9em;
    text-align: center;
  }

  &__error {
    text-align: center;
    padding: 40px 0px;
    font-size: 1.2em;
  }

  &__submitButton {
    background: #ee2e24;
    color: white;
    border: 0px;
    border-radius: 3px;
    outline: 0;
    width: 40%;
    font-size: 12px;
    padding: 7px 10px;
    font-weight: bold;
    cursor: pointer;
  }

  &__submitButtonDisabled {
    background: #9c9999;
    color: white;
    border: 0px;
    border-radius: 3px;
    outline: 0;
    width: 40%;
    font-size: 12px;
    padding: 7px 10px;
    font-weight: bold;
  }

  &__amtInput {
    border: 1px solid #9c9999 !important;
    height: 30px !important;
    border-radius: 4px;
    margin: 5px 0px 10px !important;
    width: 40% !important;
    // padding-left: 10px;
  }

  &__amtInputDisabled {
    border: 1px solid #ee2e24 !important;
    height: 30px !important;
    border-radius: 4px;
    margin: 5px 0px 10px !important;
    width: 40% !important;
    color: #bd413a;
    background-color: rgba(255, 214, 211, 0.651);
  }

  &__offlinePaymentButton {
    background: white;
    color: #ee2e24;
    border: 1px solid #ee2e24;
    border-radius: 3px;
    outline: 0;
    width: 40%;
    font-size: 12px;
    padding: 7px;
    font-weight: bold;
    cursor: pointer;
  }

  &__errorContainer {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }

  &__warning {
    width: 95%;
    margin-left: 2.5%;
    margin-bottom: 20px;

    &__btn {
      display: block;
      margin: 25px auto 0 auto;
      width: 100%;
      font-weight: 500;
      background-color: #ee2e25;
      border-color: #ee2e25;

      &:hover {
        background-color: #ee2e25;
        border-color: #ee2e25;
      }
    }
  }

  &__buttons {
    margin-bottom: 10px;
  }
}

@media all and (max-width: 991px) {
  .receipt {
    top: 0px;
    width: 100%;
    border-width: 40px 0px;

    &__footer {
      padding: 60px 10px 10px 10px;
    }

    &__submitButton,
    &__submitButtonDisabled,
    &__offlinePaymentButton,
    &__amtInput,
    &__amtInputDisabled {
      width: 60% !important;
    }
  }
}
