.header {
  &_container {
    display: flex;
    align-items: center;
    margin: 0 -20px 20px;
    color: $black;
    border-radius: 0 !important;
  }
  &__icon,
  &__text {
    line-height: 1;
  }
  &__icon {
    cursor: pointer;
  }
  &__text {
    font-family: "Brown-Bold";
    font-size: 20px;
    margin-left: 10px;
  }
}
